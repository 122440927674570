import React from 'react';

const ClipIcon: React.FC = () => (
  <img 
    src="https://ifh.cc/g/s7R0QW.png" 
    width="37" 
    height="31" 
    alt="Clip Icon" 
  />
);

export default ClipIcon;
