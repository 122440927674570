import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import '../styles/Card.css';
import ClipIcon from '../assets/svg/ClipIcon';

interface CardProps {
  photoUrl: string;
  backPhotoUrl: string;
  isSelected: boolean;
  onClick: () => void;
  rotation: number;
  noRotation: boolean;
  onClose: () => void; // 추가: onClose prop
}

const Card: React.FC<CardProps> = ({ photoUrl, backPhotoUrl, isSelected, onClick, rotation, noRotation, onClose }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (!isSelected) {
      onClick();
    } else {
      setIsFlipped(!isFlipped);
    }
  };

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose(); // 수정: onClose 호출
  };

  useEffect(() => {
    const cardContainer = cardRef.current?.parentElement;
    const isMobile = window.innerWidth <= 480;
    const scaleValue = isMobile ? 2 : 2.5;
    const yOffset = isMobile ? window.innerHeight * 0.2 : 0;

    if (isSelected && cardRef.current && cardContainer) {
      const rect = cardRef.current.getBoundingClientRect();
      gsap.to(cardContainer, {
        rotation: 0,
        duration: 0.3,
      });
      gsap.to(cardRef.current, {
        x: window.innerWidth / 2 - rect.left - rect.width / 2,
        y: window.innerHeight / 2 - rect.top - rect.height / 2 + yOffset,
        scale: scaleValue,
        zIndex: 100,
        duration: 0.3,
        onStart: () => {
          document.querySelectorAll('.card-container').forEach(container => {
            (container as HTMLElement).style.pointerEvents = 'none';
          });
        },
        onComplete: () => {
          if (cardRef.current) {
            (cardRef.current as HTMLElement).style.pointerEvents = 'auto';
          }
        },
      });
    } else if (!isSelected && cardRef.current && cardContainer) {
      setIsFlipped(false);
      gsap.to(cardContainer, {
        rotation: noRotation ? 0 : rotation,
        duration: 0.3,
      });
      gsap.to(cardRef.current, {
        x: 0,
        y: 0,
        scale: 1,
        duration: 0.3,
        onComplete: () => {
          if (cardRef.current) {
            (cardRef.current as HTMLElement).style.zIndex = '1';
          }
          document.querySelectorAll('.card-container').forEach(container => {
            (container as HTMLElement).style.pointerEvents = 'auto';
          });
        },
      });
    }
  }, [isSelected, rotation, noRotation]);

  return (
    <div
      className={`card ${isFlipped ? 'flipped' : ''}`}
      onClick={handleClick}
      ref={cardRef}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
      aria-label="View photo"
    >
      <div className="card-inner">
        <div className="clip-icon-wrapper">
          <ClipIcon />
        </div>
        <div className="card-front">
          <img src={photoUrl} alt="" className="photo" />
        </div>
        <div className="card-back">
          <img src={backPhotoUrl} alt="" className="photo" />
        </div>
      </div>
      {isSelected && (
        <>
          <div className="rotate-icon-wrapper" onClick={handleIconClick}></div>
          <div className="close-button" onClick={handleCloseClick}></div>
        </>
      )}
    </div>
  );
};

export default Card;
