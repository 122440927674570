import React, { useEffect } from 'react';
import HomePage from './pages/HomePage';

const App: React.FC = () => {
  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <HomePage />
    </div>
  );
};

export default App;
