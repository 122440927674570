import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
import '../styles/HomePage.css';

const photosByWeek = [
  [
    { "front": "https://ifh.cc/g/yKQytV.jpg", "back": "https://ifh.cc/g/tSdBZC.jpg" },
    { "front": "https://ifh.cc/g/3CkO77.jpg", "back": "https://ifh.cc/g/14zgC3.png" },
    { "front": "https://ifh.cc/g/pJx17y.jpg", "back": "https://ifh.cc/g/8fsWWz.png" },
    { "front": "https://ifh.cc/g/sDT5b7.jpg", "back": "https://ifh.cc/g/kyN4aO.png" }
  ],
  [
    { "front": "https://ifh.cc/g/dksldW.jpg", "back": "https://ifh.cc/g/1mxQkC.png" },
    { "front": "https://ifh.cc/g/FcXC4x.jpg", "back": "https://ifh.cc/g/fxLblc.png" },
    { "front": "https://ifh.cc/g/HGkGo9.jpg", "back": "https://ifh.cc/g/h8pZLr.png" },
    { "front": "https://ifh.cc/g/txxTMc.jpg", "back": "https://ifh.cc/g/GfyyTA.jpg" },
    { "front": "https://ifh.cc/g/Wh2603.jpg", "back": "https://ifh.cc/g/2t9KQV.jpg" },
    { "front": "https://ifh.cc/g/VYxrfZ.jpg", "back": "https://ifh.cc/g/oWcQpw.png" },
    { "front": "https://ifh.cc/g/Hm3DqB.jpg", "back": "https://ifh.cc/g/RDkmtS.png" }
  ],
  [
    { "front": "https://ifh.cc/g/hm7oaP.jpg", "back": "https://ifh.cc/g/bkq5zX.png" },
    { "front": "https://ifh.cc/g/kzQZg7.jpg", "back": "https://ifh.cc/g/KSZzhq.jpg" },
    { "front": "https://ifh.cc/g/7BAX26.jpg", "back": "https://ifh.cc/g/J2AjAN.png" },
    { "front": "https://ifh.cc/g/FRQw11.jpg", "back": "https://ifh.cc/g/knOzdv.png" },
    { "front": "https://ifh.cc/g/A0gbmy.jpg", "back": "https://ifh.cc/g/k8c7Bb.png" },
    { "front": "https://ifh.cc/g/Rg0oly.jpg", "back": "https://ifh.cc/g/kM6jm1.jpg" },
    { "front": "https://ifh.cc/g/3TpPRf.jpg", "back": "https://ifh.cc/g/GGjBWY.png" }
  ],
  [
    { "front": "https://ifh.cc/g/hTSZ1l.jpg", "back": "https://ifh.cc/g/VV2z4t.jpg" },
    { "front": "https://ifh.cc/g/FhoQoW.jpg", "back": "https://ifh.cc/g/Fj9SdQ.png" },
    { "front": "https://ifh.cc/g/6bMMr1.jpg", "back": "https://ifh.cc/g/JD8jPn.png" },
    { "front": "https://ifh.cc/g/zAzgLB.jpg", "back": "https://ifh.cc/g/fo03VK.jpg" },
    { "front": "https://ifh.cc/g/f6AmYN.jpg", "back": "https://ifh.cc/g/pnfbqB.png" },
    { "front": "https://ifh.cc/g/VNvwmF.jpg", "back": "https://ifh.cc/g/2vyM95.png" },
    { "front": "https://ifh.cc/g/4jCb3S.jpg", "back": "https://ifh.cc/g/whQxDm.png" }
  ],
  [
    { "front": "https://ifh.cc/g/AAlp6R.jpg", "back": "https://ifh.cc/g/yxZpc2.png" },
    { "front": "https://ifh.cc/g/VZnw1A.jpg", "back": "https://ifh.cc/g/czoo6P.jpg" },
    { "front": "https://ifh.cc/g/YTGAQo.jpg", "back": "https://ifh.cc/g/fl6SnC.png" },
    { "front": "https://ifh.cc/g/yqTFvV.jpg", "back": "https://ifh.cc/g/boWdvy.png" },
    { "front": "https://ifh.cc/g/8ODfYj.jpg", "back": "https://ifh.cc/g/c6OgMc.jpg" },
    { "front": "https://ifh.cc/g/KYdN1V.jpg", "back": "https://ifh.cc/g/jsFhwW.png" },
  ],
];

const rotations = [2.68, -1.48, 2.9, -2.47, 1.82, -2.26, 1.89, -1];

const HomePage: React.FC = () => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [noRotation, setNoRotation] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setNoRotation(true);
      } else {
        setNoRotation(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCardClick = (index: number) => {
    setSelectedPhotoIndex(index);
  };

  const handleClose = () => {
    setSelectedPhotoIndex(null);
  };

  const handleWeekChange = (week: number) => {
    setSelectedWeek(week);
    setSelectedPhotoIndex(null);
  };

  return (
    <div className="container">
      <div className="week-navigation">
        {photosByWeek.map((_, index) => (
          <React.Fragment key={index}>
            <button
              className={`week-button ${selectedWeek === index + 1 ? 'active' : ''}`}
              onClick={() => handleWeekChange(index + 1)}
            >
              {index + 1}주차
            </button>
            {index < photosByWeek.length - 1 && <span className="separator">|</span>}
          </React.Fragment>
        ))}
      </div>
      <div className={`grid ${selectedWeek === 1 ? 'first-week' : ''} ${selectedWeek === photosByWeek.length ? 'last-week' : ''}`}>
        {photosByWeek[selectedWeek - 1].map((photo, index) => (
          <div
            className={`card-container ${selectedPhotoIndex !== null && selectedPhotoIndex !== index ? 'disabled' : ''} ${selectedPhotoIndex === index ? 'selected' : ''}`}
            key={index}
            style={{ '--random-rotation': `${rotations[index % rotations.length]}deg` } as React.CSSProperties}
          >
            <Card
              photoUrl={photo.front}
              backPhotoUrl={photo.back}
              isSelected={selectedPhotoIndex === index}
              onClick={() => handleCardClick(index)}
              rotation={rotations[index % rotations.length]}
              noRotation={noRotation}
              onClose={handleClose} // 추가: handleClose 전달
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;